import checkJs from "./modules/checkJs";
import mapRealize from "./modules/map";
import date from "./modules/date";
import mask from "./modules/mask";
import menu from "./modules/menu";
import cards from "./modules/cards";
import modal from "./modules/modal";
import validate from "./modules/validate";

try {
  checkJs();
} catch (error) {
  console.log(error);
}

try {
  modal();
} catch (error) {
  console.log(error);
}

try {
  validate();
} catch (error) {
  console.log(error);
}

try {
  menu();
} catch (error) {
  console.log(error);
}

try {
  cards()
} catch (error) {
  console.log(error);
}

try {
  mapRealize();
} catch (error) {
  console.log(error);
}

try {
  date();
} catch (error) {
  console.log(error);
}

try {
  mask();
} catch (error) {
  console.log(error);
}
