const menu = () => {
  const button = document.querySelector(".header__burger");
  const header = document.querySelector(".header");
  const links = header.querySelectorAll(".nav__link");

  button.addEventListener("click", () => {
    if (header.classList.contains("header_active")) {
      header.classList.remove("header_active");
      button.classList.remove("open");
    } else {
      header.classList.add("header_active");
      button.classList.add("open");
    }
  });

  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      header.classList.remove("header_active");
      button.classList.remove("open");
    });
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > 992) {
      header.classList.remove("header_active");
      button.classList.remove("open");
    }
  })

}

export default menu;
