const mapRealize = () => {

  // Яндекс карта
  // https://yandex.ru/dev/maps/jsapi/doc/2.1/quick-start/index.html
  // https://developer.tech.yandex.ru/services/3
  function ready() {
    ymaps.ready(init);

    function init() {
      var myMap = new ymaps.Map("map", {
        center: [55.026703, 73.283347],
        zoom: 12,
        behaviors: ["drag"],
        controls: ['zoomControl', 'fullscreenControl']
      }, {
        searchControlProvider: 'yandex#search'
      });

      var placemark = new ymaps.Placemark([55.050411, 73.253335], {
        balloonContentHeader: 'ПРОИЗВОДСТВО',
        balloonContentBody: "ул. Нефтезаводская 47/3"
      }, {
        preset: 'islands#icon',
        iconColor: '#AA40E5'
      });

      var placemark2 = new ymaps.Placemark([54.997843, 73.287642], {
        balloonContentHeader: 'Офис',
        balloonContentBody: "ул. Степанца 10/4, 3 этаж, офис 301",
        balloonContentFooter: "Пн - Пт 9:00 - 18:00"
      }, {
        preset: 'islands#icon',
        iconColor: '#C62E7A'
      });

      myMap.geoObjects.add(placemark);
      myMap.geoObjects.add(placemark2);
    }

    // Ищем и удаляем мусор с карты (кроме логотипа и копирайта)
    function delets() {
      let elem = document.querySelector(".ymaps-2-1-79-gototech");

      if (elem) {
        elem.parentNode.removeChild(elem);
      }
    }

    setTimeout(delets, 2000);
  }

  document.addEventListener("DOMContentLoaded", ready);
}

export default mapRealize;
